<template>
  <b-modal id="appointment-docs-modal" size="lg" hide-header hide-footer centered>
    <div class="header">
      <p>Documentos</p>
      <v-close @click="closeModal" />
    </div>

    <div class="body">
      <span v-for="(doc, index) in docs" :key="index">
        <div class="file-container">
          <v-pdf />
          <p v-if="parseInt(doc[0]?.type_id) === 0 && doc[0]?.attendance?.type === 'PRE_CONSULTATION'">
            Pré-consulta
          </p>
          <p v-else-if="doc[0]?.length >= 2">
            {{ doc[0]?.group_name }}
          </p>
          <p v-else-if="parseInt(doc[0]?.type_id) >= 0 && ![39, 40, 41, 42, 43].includes(parseInt(doc[0]?.type_id))">
            {{ typeDoc[doc[0]?.type_id] }}
          </p>
          <p v-else-if="doc[0]">
            {{ doc[0]?.custom_name ?? 'Arquivo' }}
          </p>
          <p v-else>
            {{ doc?.custom_name ?? 'Arquivo' }}
          </p>
        </div>

        <div class="print-options">
          <Icon
            v-if="parseInt(doc?.type_id) >= 0 && doc?.attendance?.type !== 'PRE_CONSULTATION' && doc"
            tooltip="Versão para impressão de laudo/paciente"
            placement="left"
          >
            <Printer class="icon-print" @click="openPrintableDocument(doc)" />
          </Icon>
          <Icon
            v-if="parseInt(doc[0]?.type_id) >= 0 && doc[0]?.attendance?.type !== 'PRE_CONSULTATION' && doc[0]"
            tooltip="Versão para impressão atendimento/paciente"
            placement="left"
          >
            <Printer class="icon-print" @click="openPrintableDocument(doc[0])" />
          </Icon>
          <Icon tooltip="Versão original" placement="right" v-if="doc[0]">
            <File class="icon-file" @click="openFile(doc)" />
          </Icon>
        </div>
      </span>
    </div>
  </b-modal>
</template>
<script>
import { mergePrintableAllPDFs } from '@/utils/PDFHelper'
import { getDocTypes } from '@/utils/documentHelper.js'
import Close from '@/assets/icons/close.svg'
import Pdf from '@/assets/icons/pdf.svg'
import Icon from '@/components/General/Icon'
import Printer from '@/assets/icons/printer.svg'
import File from '@/assets/icons/file.svg'

export default {
  components: {
    'v-close': Close,
    'v-pdf': Pdf,
    Icon,
    Printer,
    File,
  },
  props: {
    docs: { type: Array, required: true }
  },
  data() {
    return {
      typeDoc: getDocTypes(),
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('appointment-docs-modal')
    },
    async openPrintableDocument(attendanceFile) {
      const loading = this.$loading.show()
      try {
        const url = await mergePrintableAllPDFs([attendanceFile])
        window.open('about:blank').document.write(`
          <html>
            <body style="margin:0;">
              <iframe width="100%" height="100%" src="${url}" style="border:none" >
            </body>
          </html>
        `)
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async getDocUrl(path){
      const loading = this.$loading.show()
      try {
        const res = await this.api.getDocTemporaryUrl(path)
        window.open(res.data, '_blank')
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async openFile(file) {
      file.map(async doc => {
        await this.getDocUrl(doc.path)
      })
    }
  }
}
</script>

<style lang="scss">
* {
  font-family: 'Nunito Sans';
  font-style: normal;
}

#appointment-docs-modal {
  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 8px;
    border: none;

    .modal-body {
      padding: 0;

      .header {
        padding: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        svg {
          height: 24px;
          width: 24px;
          fill: var(--neutral-500);
        }
      }

      .body {
        border-top: 1px solid var(--neutral-200);
        padding: 12px 0;
        overflow-x: hidden;
        overflow-y: scroll;
        white-space: nowrap;
        max-height: 300px;

        span {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 0 24px;
          padding: 12px 0;

          .file-container {
            display: flex;
            flex-direction: row;
            gap: 20px;

            svg {
              height: 24px;
              width: 24px;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: var(--type-active);
            }
          }

          .print-options {
            display: flex;
            gap: 10px;

            .icon-print {
              width: 30px;
              height: 30px;
              stroke: var(--blue-700);
              cursor: pointer;
            }

            .icon-file {
              width: 24px;
              height: 24px;
              stroke: var(--blue-700);
              cursor: pointer;
            }
          }

          a {
            text-decoration: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: var(--blue-500);
            cursor: pointer;
          }
        }

        span + span {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid var(--neutral-200);
          margin: 0 24px;
          padding: 12px 0;

          .file-container {
            display: flex;
            flex-direction: row;
            gap: 20px;

            svg {
              height: 24px;
              width: 24px;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: var(--type-active);
            }
          }

          a {
            text-decoration: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: var(--blue-500);
            cursor: pointer;
          }
        }
      }

      .body::-webkit-scrollbar {
        width: 12px;
      }

      .body::-webkit-scrollbar-track {
        background: var(--neutral-000);
        border-radius: 0 0 8px 0;
      }

      .body::-webkit-scrollbar-thumb {
        background-color: var(--neutral-300);
        border-radius: 100px;
        border: 3px solid var(--neutral-000);
      }
    }
  }
}
</style>
